import {Component, Input} from '@angular/core';
import {InputLabelComponent} from '@shared/components/forms/components/input-label/input-label.component';
import {InputTextModule} from 'primeng/inputtext';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {NgxMaskDirective, NgxMaskPipe} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {BasicFormInputComponent} from '@shared/components/forms/basic/basic-form-input.component';
import {CommonModule} from '@angular/common';
import {InputTextareaModule} from 'primeng/inputtextarea';


@Component({
  selector: 'app-het-textarea',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskPipe,
    NgxMaskDirective,
    KeenIconComponent,
    TranslateModule,
    FormsModule,
    InputLabelComponent,
    InputTextModule,
    InputTextareaModule
  ],
  templateUrl: './het-textarea.component.html',
  styleUrl: './het-textarea.component.scss'
})
export class HetTextareaComponent extends BasicFormInputComponent {
  @Input() readonly = false;
  @Input() rows = 4;
}
